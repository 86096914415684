import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Imprint`}</h1>
    <p>{`relayts UG (haftungsbeschränkt)`}<br />{`
Beskidenstr. 18A`}<br />{`
14129 Berlin`}<br />{`
Deutschland`}</p>
    <p>{`+49 162 649 41 95`}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:info@relayts.com"
      }}>{`info@relayts.com`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.relayts.com"
      }}>{`www.relayts.com`}</a></p>
    <p>{`Managing director: Jan Willumeit`}<br />{`
Register court (Amtsgericht): Charlottenburg (Berlin)`}<br />{`
Register number: HRB 220672 B`}<br />{`
VAT ID no: DE337374054`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      